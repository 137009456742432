import { imageFontLink, contentContainerStyle, contentStyle, imageBackdrop, imageFontTitle, imageFontSubtitle, imageStyleLoginSignup, mainContainerStyle } from "./style";

import { IsMobileViewContext } from "../../IsMobileViewContext";
import { useContext } from "react";

interface ILoginSignupProps {
    image : string,
    imageTitle: string,
    imageSubtitle: string,
    children : JSX.Element
};

export function LoginSignup(props : ILoginSignupProps) {
    const {
        image,
        imageTitle,
        imageSubtitle,
        children
    } = props;

    const isMobileView = useContext(IsMobileViewContext);

    return (
        <div style = {mainContainerStyle()}>
            <div style = {contentContainerStyle()}>
                <div style = {contentStyle(isMobileView, true)}>
                    {children}
                </div>
                <div style = {contentStyle(isMobileView, !isMobileView)}>
                    <div style= {imageBackdrop()}>
                        <p style = {imageFontTitle()}>{imageTitle}</p>
                        <p style = {imageFontSubtitle()}>{imageSubtitle}</p>
                        <p style = {imageFontSubtitle()}>Do you have any question? <a href="#" style = {imageFontLink()}>Contact us</a></p>
                    </div>
                    <img
                        src = {image}
                        alt = "Login"
                        style = {imageStyleLoginSignup()}
                    />
                </div>
            </div>
        </div>
    );
}
