import { Navigate, useNavigate } from "react-router-dom";
import { inputFileImage, inputFileContainer, displayNone, loader, cardStyle,logoStyle, titleStyle, errorMessageStyle, formStyle, submitBtnContainerStyle, submitBtnStyle, goToSignupContainerStyle, noAccountStyle, goToSignupStyle } from "./style";
import Image from "../../assets/img_signup.jpg";
import Logo from "../../assets/logo.svg";
import loaderImg from "../../assets/loader.png";
import Avatar from "../../assets/avatar.svg";

import { IsMobileViewContext } from "../../IsMobileViewContext";
import { useContext, useState } from "react";
import { CometChatUIKit } from "@cometchat/chat-uikit-react";

import { AppConstants } from "../../AppConstants";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { CometChatThemeContext } from "@cometchat/chat-uikit-react";
import { LoginSignup } from "../LoginSignup";
import { TextInput } from "../TextInput";

interface ISignUp {
    loggedInUser : CometChat.User | null | undefined,
    setLoggedInUser : React.Dispatch<React.SetStateAction<CometChat.User | null | undefined>>,
    setInterestingAsyncOpStarted : React.Dispatch<React.SetStateAction<boolean>>
};

export function Signup({ loggedInUser, setLoggedInUser, setInterestingAsyncOpStarted } : ISignUp) {
    const [loaderContainer, setLoaderContainer] = useState(false);
    const [name, setName] = useState("");
    const [userName, setUserName] = useState("");
    const [inviteCode, setInvateCode] = useState("HOPI");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [age, setAge] = useState("");
    const [password, setPassword] = useState("");
    const [previewImage, setPreviewImage] = useState<any | null>(Avatar);
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();
    const { theme } = useContext(CometChatThemeContext);
    const isMobileView = useContext(IsMobileViewContext);
    async function newUserSignUp(e : React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setLoaderContainer(true)
        
        const rawResponse = await fetch('https://us-east1-roboflowinferencev1.cloudfunctions.net/tol-v2/users', {
            method: 'POST',
            mode: 'cors', 
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'apikey': '387b9660d75bc743f885296ec30efada1415b06b',
                
            },
            body: JSON.stringify(
                {
                    "metadata": {
                      "@private": {
                        "email": email,
                        "contactNumber": phone,
                        "inviteCode": inviteCode,
                        "realName": name,
                        "password": password
                      }
                    },
                    "uid": userName,
                    "realAge": age,
                    "realImage": previewImage
                })
        })
        .then(response => response.json())
        .then(data => {
            if(!data.error) {
                CometChatUIKit.getLoggedinUser()
                    .then((user) => {
                        if (!user) {
                        //Login user
                        CometChatUIKit.loginWithAuthToken(data.authtoken)
                            .then((user) => {
                            console.log("Login Successful:", { user });
                                setLoggedInUser(user)
                                navigate("/home/users-module/users-with-messages");
                            })
                            .catch(console.log);
                        } else {
                        //user already logged in
                            navigate("/home/users-module/users-with-messages");
                        }
                    })
                    .catch(console.log);
            } else {
                setLoaderContainer(false)
                throw new Error(data.error);
            }
        })
        .catch(e => setErrorMessage(e.message))
    };

    if (loggedInUser === undefined) {
        return null;
    }

    if (loggedInUser) {
        return <Navigate to = "/home/users-module/users-with-messages" />;
    }


    function getErrorMessage() {
        if (!errorMessage) {
            return null;
        }
        return (
            <div
                style = {errorMessageStyle(isMobileView)}
            >
                {errorMessage}
            </div>
        );
    }

    function handleSelectImage(event : any) {
        debugger
        console.log(event)
        const file = event.target.files[0];
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
            debugger
            setPreviewImage(fileReader.result);
        });
        fileReader.readAsDataURL(file);
    }


    return (
        <LoginSignup
            image = {Image}
            imageTitle= "Find Your Tribe, Share Joy and Embrace Spirituality."
            imageSubtitle= "Connecting kind souls, dreamers, and adventurers."
        >
            <>
                { loaderContainer && 
                    <div style = {loader()}>
                        <img src = {loaderImg} width = "120" />
                        Creating account...
                    </div>
                }
                <div style = {loaderContainer ? displayNone() : cardStyle()}>
                    <div>
                        <img
                            style = {logoStyle()}
                            src = {Logo}
                            alt = "Tribal Love Logo"
                            width = "130"
                        />
                        <div style = {titleStyle(theme)}>
                            Sign Up and join Tribal Love
                        </div>
                    </div>

                    <form
                        onSubmit = {newUserSignUp}
                        style = {formStyle()}
                    >
                        <TextInput
                            labelText = "Invite Code"
                            placeholderText = "Enter your code here"
                            value = {inviteCode}
                            onValueChange = {setInvateCode}                    
                        />
                        <TextInput
                            labelText = "First and Last name"
                            placeholderText = "Enter your full name here"
                            value = {name}
                            onValueChange = {setName}
                            pattern="[a-zA-Z\s]+"
                            required
                        />
                        <TextInput 
                            type = "email"
                            labelText = "Email"
                            placeholderText = "Enter your email address"
                            value = {email}
                            onValueChange = {setEmail}
                            required
                        />
                        <TextInput 
                            labelText = "Nick name (3-10 chars)"
                            placeholderText = "Enter a nickname"
                            value = {userName}
                            onValueChange = {setUserName}
                            minLength = {3}
                            maxLength = {10}
                            pattern="[a-zA-Z0-9]+"
                            required
                        />
                        <TextInput 
                            type = "tel"
                            labelText = "Contact number with country code"
                            placeholderText = "Enter your phone number as 4477245234"
                            value = {phone}
                            onValueChange = {setPhone}
                            required
                        />
                        <TextInput 
                            type = "number"
                            labelText = "Your Real Age"
                            placeholderText = "Enter your age (21+)"
                            value = {age}
                            onValueChange = {setAge}
                            required
                            min = {21}
                            max = {95} 
                        />
                        <div style = {inputFileContainer()}>    
                            <TextInput 
                                type = "file"
                                labelText = "Your avatar or real image"
                                onValueChange = {handleSelectImage}
                                required
                            />
                            {
                                previewImage && <img style = {inputFileImage()} src={previewImage} alt="preview-image" />
                            }
                        </div> 
                        <TextInput 
                            type = "password"
                            labelText = "Password"
                            placeholderText = "Create a password"
                            value = {password}
                            onValueChange = {setPassword}
                            required
                        />
                        <div style = {submitBtnContainerStyle(isMobileView)}>    
                            {getErrorMessage()}

                            <button type = "submit" style = {submitBtnStyle(theme)}>
                                Sign Up
                            </button>


                            <div style = {goToSignupContainerStyle()}>
                                <div style = {noAccountStyle(theme)}>
                                    Already have an account?
                                    <span
                                        onClick = {() => navigate("/login")}
                                        style = {goToSignupStyle(theme)}
                                    >
                                        Login
                                    </span>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            </>
        </LoginSignup>
    );
}
