import { inputStyle, labelContainerStyle, labelStyle, labelTextStyle, requiredSymbolStyle } from "./style";

import { CometChatThemeContext } from "@cometchat/chat-uikit-react";
import { useContext } from "react";

interface ITextInputProps {
    type? : string,
    labelText : string,
    placeholderText? : string,
    value? : string,
    onValueChange : (newValue : string | any) => void
    required? : boolean
    minLength? : number
    maxLength? : number
    min? :number
    max? : number
    pattern? : string
    imageFile? : any
};

export function TextInput(props : ITextInputProps) {
    const {
        type = "text", 
        labelText,
        placeholderText,
        value,
        onValueChange,
        required = false,
        minLength,
        maxLength,
        min,
        max, 
        pattern,
        imageFile
    } = props;

    const { theme } = useContext(CometChatThemeContext);

    function getLabel() {
        let labelJSX = (
            <span
                style = {labelTextStyle(theme)}
            >
                {labelText}
            </span>
        );
        if (required && type !== "file") {
            labelJSX = (
                <span
                    style = {labelContainerStyle()}
                >
                    {labelJSX}
                    <span
                        style = {requiredSymbolStyle()}
                    >
                        *
                    </span>
                </span>
            );
        }
        if (required && type === "file") {
            labelJSX = (
                <>
                    <span style = {labelContainerStyle()}>
                        {labelJSX}
                        <span style = {requiredSymbolStyle()}>*</span>
                    </span>
                    <div style = {inputStyle(theme)}>
                        Select your image
                    </div>
                </>
            );
        }
        return labelJSX;
    }

    return (
        <label
            style = {labelStyle()}
        >
            {getLabel()}
            <input
                type = {type}
                placeholder = {placeholderText}
                value = {value}
                onChange = {type !== "file" ? e => onValueChange(e.target.value) : e => onValueChange(e)}
                required = {required}
                style = {type !== "file" ? inputStyle(theme) : inputStyle(theme, false)}
                minLength = {minLength}
                maxLength = {maxLength}
                min = {min}
                max = {max}
                pattern = {pattern}
            />
        </label>
    );
}
