import { Navigate, useNavigate } from "react-router-dom";
import '@cometchat/uikit-elements';//import the web component package.
import { displayNone, loader, cardStyle, titleStyle, logoStyle, errorMessageStyle, goToSignupContainerStyle, goToSignupStyle, loginBtnStyle, loginSampleUsersContainerStyle, loginStyle, loginUidFormStyle, noAccountStyle, userAvatarStyle, userBtnStyle, userNameAndUidContainerStyle, userNameStyle, userUidStyle, usingSampleUsersTextStyle } from "./style";
import Image from "../../assets/img_login.jpg";
import Logo from "../../assets/logo.svg";
import loaderImg from "../../assets/loader.png";
import { IsMobileViewContext } from "../../IsMobileViewContext";
import { useContext, useEffect, useState } from "react";

import { CometChat } from "@cometchat/chat-sdk-javascript";
import { CometChatThemeContext } from "@cometchat/chat-uikit-react";
import { CometChatUIKit } from "@cometchat/chat-uikit-react"
import { LoginSignup } from "../LoginSignup";
import { TextInput } from "../TextInput";
import { users } from "../../sampleApp/sampledata";


const loaderIcon = "https://img.icons8.com/?size=24&id=102555&format=svg";

interface ILoginProps {
    loggedInUser : CometChat.User | null | undefined,
    setLoggedInUser : React.Dispatch<React.SetStateAction<CometChat.User | null | undefined>>
    setInterestingAsyncOpStarted : React.Dispatch<React.SetStateAction<boolean>>
};

type User = {
    name : string,
    uid : string,
    avatar : string
};

type UserJson = {
    users : User[]
}

export function Login(props : ILoginProps) {
    const {
        loggedInUser,
        setLoggedInUser,
        setInterestingAsyncOpStarted
    } = props;
    const [loaderContainer, setLoaderContainer] = useState(false);
    const [uid, setUid] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();
    const { theme } = useContext(CometChatThemeContext);
    const [defaultUsers, setDefaultUsers ] = useState<User[]>([]);
    const isMobileView = useContext(IsMobileViewContext);


    async function fetchDefaultUsers() {
        try {
            const response = await fetch("https://assets.cometchat.io/sampleapp/sampledata.json");
            const data : UserJson = await response.json();
            setDefaultUsers(data.users);
        }
        catch(error) {
            console.log("fetching default users failed, using fallback data", error);
            setDefaultUsers(users.users);
        }
    }

    async function login(uid : string) {
        try {
            setInterestingAsyncOpStarted(true);
            CometChatUIKit.login(uid)?.then(loggedInUser => {

                console.log("Login successful, loggedInUser:", loggedInUser);
                setLoggedInUser(loggedInUser);
                navigate("/home/users-module/users-with-messages");

            })
        }
        catch(error) {
            console.log("login failed", error);
            if (error instanceof CometChat.CometChatException && error.message) {
                setErrorMessage(error.message);
            }
            console.log(error);
        }
        finally {
            setInterestingAsyncOpStarted(false);
        }
    }


    async function handleSignIn(e : React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setLoaderContainer(true)
        await fetch('https://us-east1-roboflowinferencev1.cloudfunctions.net/tol-v2/users', {
            method: 'POST',
            mode: 'cors', 
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'apikey': '387b9660d75bc743f885296ec30efada1415b06b',
                
            },
            body: JSON.stringify(
                {
                    "metadata": {
                      "@private": {
                        "password": password
                      }
                    },
                    "uid": uid,
                })
        })
            .then(response => response.json())
            .then(data => {
                if(!data.error) {
                    CometChatUIKit.getLoggedinUser()
                        .then((user) => {
                            if (!user) {
                            //Login user
                            CometChatUIKit.loginWithAuthToken(data.authtoken)
                                .then((user) => {
                                console.log("Login Successful:", { user });
                                    setLoggedInUser(user)
                                    navigate("/home/users-module/users-with-messages");
                                })
                                .catch(console.log);
                            } else {
                            //user already logged in
                                navigate("/home/users-module/users-with-messages");
                            }
                        })
                        .catch(console.log);
                } else {
                    setLoaderContainer(false)
                    throw new Error(data.error);
                }
            })
            .catch(e => setErrorMessage(e.message))
    };


    function getErrorMessage() {
        if (!errorMessage) {
            return null;
        }
        return (
            <div
                style = {errorMessageStyle(isMobileView)}
            >
                {errorMessage}
            </div>
        );
    }

    useEffect(()=>{
        fetchDefaultUsers();
     return () =>{
        setDefaultUsers([]);
     }
    },[])

    if (loggedInUser === undefined) {
        return null;
    }

    if (loggedInUser) {
        return <Navigate to = "/home/users-module/users-with-messages" />;
    }
    return (
        <LoginSignup
            image = {Image}
            imageTitle= "Welcome back"
            imageSubtitle= "Login to your account and continue connecting with kind souls, dreamers and adventurers."
        >   
            <>
                { loaderContainer && 
                    <div style = {loader()}>
                        <img src = {loaderImg} width = "120" />
                        Loading
                    </div> }
                <div style = {loaderContainer ? displayNone() : cardStyle()}>
                    <div>
                        <img
                            style = {logoStyle()}
                            src = {Logo}
                            alt = "Tribal Love Logo"
                            width = "130"
                        />
                        <div style = {titleStyle(theme)}>
                            Login to your account
                        </div>
                    </div>

                    <form
                        onSubmit = {handleSignIn}
                        style = {loginUidFormStyle()}
                    >
                        <TextInput
                            labelText = "User name"
                            placeholderText = "Enter your user name"
                            value = {uid}
                            onValueChange = {setUid}
                            required
                        />
                        
                        <TextInput
                            type = "password"
                            labelText = "Password"
                            placeholderText = "Enter your password here"
                            value = {password}
                            onValueChange = {setPassword}
                            required
                        />
                        {getErrorMessage()}
                        <button
                            style = {loginBtnStyle()}
                        >
                            Login
                        </button>
                        <div style = {goToSignupContainerStyle()}>
                            <div style = {noAccountStyle(theme)}>
                                Don't have an account? 
                                <span
                                    onClick = {() => navigate("/signup")}
                                    style = {goToSignupStyle(theme)}
                                >
                                    Sign Up
                                </span>
                            </div>
                        </div>
                    </form>
                    

                </div>
            </>
        </LoginSignup>
    );
}
