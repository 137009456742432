import { CSSProperties } from "react";
import { CometChatTheme, fontHelper } from "@cometchat/uikit-resources";

export function loginStyle() : CSSProperties {
    return {
        display: "flex",
        flexDirection: "column",
        rowGap: "48px"
    };
}

export function userBtnStyle(theme : CometChatTheme) : CSSProperties {
    return {
        flexBasis: "48%",
        padding: "8px",
        backgroundColor: theme.palette.getAccent100("light"),
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        columnGap: "8px",
        cursor: "pointer",
        border: `1px solid ${theme.palette.getAccent100("light")}`,
    };
}

export function userAvatarStyle() : CSSProperties {
    return {
        width: "32px",
        height: "32px",
        backgroundColor: "white",
        borderRadius: "24px"
    };
}

export function defaultUserBtnsContainerStyle() : CSSProperties {
    return {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(220px, 1fr))",
        gap: "8px"
    };
}

export function loginSampleUsersContainerStyle() : CSSProperties {
    return {
        display: "flex",
        flexDirection: "column",
        rowGap: "4px"
    }
}

export function loginBtnStyle() : CSSProperties {
    return {
        backgroundColor: "#8B613C",
        color: "white",
        padding: "16px 32px",
        border: '1px solid rgba(255,255,255,.5)',
        borderRadius: "64px",
        width: "100%",
        cursor: "pointer",
        fontSize: "16px"
    };
}


export function loginUidFormStyle() : CSSProperties {
    return {
        display: "flex",
        flexDirection: "column",
    };
}

export function goToSignupContainerStyle() : CSSProperties {
    return {
        paddingTop: "16px",
        display: "flex",
        flexDirection: "column",
        rowGap: "16px",
        alignItems: "center",
        paddingBottom: "32px",
        textAlign: "center"
    };
}

export function goToSignupStyle(theme : CometChatTheme) : CSSProperties {
    return {
        font: fontHelper(theme.typography.title2),
        textDecoration: "underline",
        fontWeight: "bold",
        marginLeft: "4px",
        cursor: "pointer"
    };
}

export function usingSampleUsersTextStyle(theme : CometChatTheme) : CSSProperties {
    return {
        font: fontHelper(theme.typography.subtitle2),
        color: theme.palette.getAccent600("light")
    };
}

export function noAccountStyle(theme : CometChatTheme) : CSSProperties {
    return {
        font: fontHelper(theme.typography.subtitle1),
        color: theme.palette.getAccent600("light")
    };
}

export function userNameStyle(theme : CometChatTheme) : CSSProperties {
    return {
        textTransform: "capitalize",
        font: fontHelper(theme.typography.name),
        color: theme.palette.getAccent("light"),
        textAlign: "left"
    };
}

export function userUidStyle(theme : CometChatTheme) : CSSProperties {
    return {
        font: fontHelper(theme.typography.subtitle2),
        color: theme.palette.getAccent600("light")
    };
}

export function userNameAndUidContainerStyle() : CSSProperties {
    return {
        display: "flex",
        flexDirection: "column",
        rowGap: "2px", 
        alignItems: "flex-start"
    };
}

export function errorMessageStyle(isMobileView : boolean) : CSSProperties {
    return {
        fontFamily: "Quicksand, sansSerif",
        fontSize: "16px",
        color: "white",
        padding: "1em 0",
        background: "red",
        position: "fixed",
        top: "0",
        left: "0",
        width: isMobileView ? "100%" : "50%",
    };
}


export function logoStyle() : CSSProperties {
    return {
        width: "130px",
        margin: "0 auto 32px auto"
    }
}

export function titleStyle(theme : CometChatTheme) : CSSProperties {
    return {
        font: fontHelper(theme.typography.heading),
        color: theme.palette.getAccent()
    };
}

export function cardStyle() : CSSProperties {
    return {
        width: "100%",
        maxWidth: "480px",
        margin: "0 auto",
        backgroundColor: "white",
        height: "100vh",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
        gap: "80px 0",
        padding: "5vh 32px"
    };
}


export function loader() : CSSProperties {
    return {
        position: "absolute",
        inset: "0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "40px",
        zIndex: "99",
        background: "white",
        fontFamily: "Quicksand, sansSerif",
        fontSize: "24px",


    };
}

export function displayNone() : CSSProperties {
    return {
        display: "none"
    };
}

