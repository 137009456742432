import { CSSProperties } from "react";
import { CometChatTheme, fontHelper } from "@cometchat/uikit-resources";

export function loginSignupStyle() : CSSProperties {
    return {
        height: "100%",
        display: "flex",
        justifyContent: "center"
    };
}

export function mainContainerStyle() : CSSProperties {
    return {
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        rowGap: "0",
        boxSizing: "border-box",
        padding: "0"
    };
}

export function headerStyle(theme : CometChatTheme) : CSSProperties {
    return {
        width: "100%",
        padding: "16px",
        display: "flex",
        justifyContent: "space-between",
        borderBottom: `1px solid ${theme.palette.getAccent100()}`
    };
}

export function contentContainerStyle() : CSSProperties {
    return {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        columnGap: "0"
    };
}

export function footerStyle(theme : CometChatTheme) : CSSProperties {
    return {
        padding: "16px",
        flexGrow: "1",
        font: fontHelper(theme.typography.subtitle2),
        color: theme.palette.getAccent500(),
        display: "flex",
        alignItems: "flex-end"
    };
}

export function chatHeaderTextStyle() : CSSProperties {
    return {
        fontWeight: "bold"
    };
}

export function contentStyle(isMobileView : boolean, visible : boolean) : CSSProperties {
    return {
        width: isMobileView ? "100%" : "50%",
        height: "100vh",
        display: visible ? "block" : "none",
        position: "relative",
        overflow: "auto"
    };
}


export function imageStyle(showImage : boolean) : CSSProperties {
    return {
        display: showImage ? "inline" : "none",
        width: "50%",
        backgroundSize: "contain"
    };
}

export function imageFontTitle() : CSSProperties {
    return {
        color: "white",
        fontSize: "3.5em",
        fontFamily: "Quicksand, sansSerif"
    }
}

export function imageFontSubtitle() : CSSProperties {
    return {
        color: "white",
        fontSize: "1em",
        fontFamily: "Quicksand, sansSerif"
    }
}

export function imageFontLink() : CSSProperties {
    return {
        color: "white",
        fontSize: "1em",
        fontFamily: "Quicksand, sansSerif",
    }
}

export function imageBackdrop() : CSSProperties {
    return {
        padding: "80px",
        boxSizing: "border-box",
        position: "absolute",
        inset: "0",
        zIndex: "0",
        opacity: ".8",
        backgroundImage: "linear-gradient(0deg, #000, #000000bf 30%, #0000 59%)",
        display: "flex",
        flexDirection: "column",
        justifyContent:  "flex-end",
        gap: "16px 0"
    }
}


export function imageStyleLoginSignup() : CSSProperties {
    return {
        display: "block",
        width: "100%",
        height: "100%",
        objectFit: "cover"
    };
}

export function headerTitle(theme : CometChatTheme) : CSSProperties {
    return {
        font: fontHelper(theme.typography.name)
    };
}

export function versionStyle(theme : CometChatTheme) : CSSProperties {
    return {
        font: fontHelper(theme.typography.subtitle2),
        color: theme.palette.getAccent400()
    };
}
