import { CSSProperties } from "react";
import { CometChatTheme, fontHelper } from "@cometchat/uikit-resources";

export function formStyle() : CSSProperties {
    return {
        display: "flex",
        flexDirection: "column",
        paddingBottom: "150px"
    };
}

export function generateUidCheckboxStyle() : CSSProperties {
    return {
        width: "24px",
        height: "24px",
        cursor: "pointer"
    };
}

export function generateUidStyle() : CSSProperties {
    return {
        display: "flex",
        columnGap: "4px",
        alignItems: "center",
        marginBottom: "16px"
    };
}

export function checkboxTextStyle(theme : CometChatTheme) : CSSProperties {
    return {
        font: fontHelper(theme.typography.subtitle2),
        color: theme.palette.getAccent600("light")
    };
}

export function submitBtnContainerStyle(isMobileView : boolean) : CSSProperties {
    return {
        backgroundColor: "white",
        padding: "16px 32px",
        borderTop: '1px solid rgba(0,0,0,.2)',
        width: isMobileView ? "100%" : "50%",
        position: "fixed",
        bottom: 0,
        left:0,
        boxSizing: "border-box"

    };
}
export function submitBtnStyle(theme : CometChatTheme) : CSSProperties {
    return {
        backgroundColor: "#8B613C",
        color: "white",
        padding: "16px 32px",
        border: '1px solid rgba(255,255,255,.2)',
        borderRadius: "64px",
        width: "100%",
        cursor: "pointer",
        fontSize: "16px",
        maxWidth: "416px"
    };
}

export function errorMessageStyle(isMobileView : boolean) : CSSProperties {
    return {
        fontFamily: "Quicksand, sansSerif",
        fontSize: "16px",
        color: "white",
        padding: "1em 0",
        background: "red",
        position: "fixed",
        top: "0",
        left: "0",
        width: isMobileView ? "100%" : "50%",
    };
}

export function goToSignupContainerStyle() : CSSProperties {
    return {
        paddingTop: "16px",
        display: "flex",
        flexDirection: "column",
        rowGap: "16px",
        alignItems: "center",
        paddingBottom: "32px",
        textAlign: "center"
    };
}

export function goToSignupStyle(theme : CometChatTheme) : CSSProperties {
    return {
        font: fontHelper(theme.typography.title2),
        textDecoration: "underline",
        fontWeight: "bold",
        marginLeft: "4px",
        cursor: "pointer"
    };
}

export function noAccountStyle(theme : CometChatTheme) : CSSProperties {
    return {
        font: fontHelper(theme.typography.subtitle1),
        color: theme.palette.getAccent600("light")
    };
}

export function logoStyle() : CSSProperties {
    return {
        width: "130px",
        margin: "0 auto 32px auto"
    }
}

export function titleStyle(theme : CometChatTheme) : CSSProperties {
    return {
        font: fontHelper(theme.typography.heading),
        color: theme.palette.getAccent()
    };
}

export function cardStyle() : CSSProperties {
    return {
        width: "100%",
        maxWidth: "480px",
        margin: "0 auto",
        backgroundColor: "white",
        height: "100vh",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        gap: "80px 0",
        padding: "5vh 32px"
    };
}


export function loader() : CSSProperties {
    return {
        position: "absolute",
        inset: "0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "40px",
        zIndex: "99",
        background: "white",
        fontFamily: "Quicksand, sansSerif",
        fontSize: "24px"
    };
}


export function displayNone() : CSSProperties {
    return {
        display: "none"
    };
}

export function inputFileContainer() : CSSProperties {
    return {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    };
}

export function inputFileImage() : CSSProperties {
    return {
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        position: "relative",
        top: "-8px",
        border: "solid 1px rgb(139, 97, 60)",
        objectFit: "cover"
    };
}

