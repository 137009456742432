import { CSSProperties } from "react";
import { CometChatTheme, fontHelper } from "@cometchat/uikit-resources";

export function labelStyle() : CSSProperties {
    return {
        display: "flex",
        flexDirection: "column",
        rowGap: "4px",
        textAlign: "left",
        minWidth: "70%"
    };
}

export function labelTextStyle(theme : CometChatTheme) : CSSProperties {
    return {
        font: fontHelper(theme.typography.subtitle2),
        color: theme.palette.getAccent600("light")
    };
}

export function inputStyle(theme : CometChatTheme, visible : boolean = true) : CSSProperties {
    return {
        padding: "16px 32px",
        border: '1px solid #F2F2F2',
        borderRadius: "64px",
        background: "#F9F9F9",
        marginBottom: "40px",
        font: fontHelper(theme.typography.subtitle2),
        display: visible ? "block" : "none"
    };
}

export function requiredSymbolStyle() : CSSProperties {
    return {
        color: "red"
    };
}

export function labelContainerStyle() : CSSProperties {
    return {
        display: "flex",
        columnGap: "1px"
    }
}
